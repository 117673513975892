<template>
  <div class="input-group mb-3">
    <input v-model="password"
           type="password"
           class="form-control border-end-0 shadow-none"
           id="password"
           placeholder="Hasło"
           @focusin="isPasswordInputFocused = true"
           @focusout="isPasswordInputFocused = false"
    >
    <span class="input-group-text border-start-0 fm-input-group-text "
          v-bind:class="{'fm-form-control-focused': isPasswordInputFocused}"
          style="background-color: white"
          id="togglePasswordSpan">
      <i class="bi bi-eye"
         id="togglePassword"
         v-on:click="togglePassword">
      </i>
    </span>
  </div>
</template>

<script>
export default {
  name: 'InputPassword',
  emits: ['passwordChanged'],
  watch: {
    password() {
      this.$emit('passwordChanged', this.password);
    }
  },
  data() {
    return {
      password: null,
      isPasswordInputFocused: false
    };
  },
  methods: {
    togglePassword() {
      const togglePasswordElement = document.getElementById('togglePassword');
      const passwordElement = document.getElementById('password');

      if (togglePasswordElement.classList.contains('bi-eye-slash')) {
        togglePasswordElement.classList.remove('bi-eye-slash');
        togglePasswordElement.classList.add('bi-eye');
        passwordElement.setAttribute('type', 'password');
      } else {
        togglePasswordElement.classList.remove('bi-eye');
        togglePasswordElement.classList.add('bi-eye-slash');
        passwordElement.setAttribute('type', 'text');
      }
    }
  }
};
</script>

<style scoped>
.fm-form-control-focused {
  border-color: var(--bootstrap-border-color-on-focus);
}
</style>
