import axios from 'axios';

export class LoginService {
  static async login(userIdentity, password) {
    return await axios.post(
      process.env.VUE_APP_DOMAIN + '/rest/login',
      {
        userIdentity: userIdentity,
        password: password
      },
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );
  }

  static async sendResetPasswordLink(userEmail) {
    return await axios.post(
      process.env.VUE_APP_DOMAIN + '/rest/user/users/password/token',
      {
        email: userEmail
      },
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );
  }
}

const loginService = LoginService;
export default loginService;
