<template>
  <div class="container-fluid">
  <template v-if="deviceType === 'desktop'">
    <div class="row fm-background-navbar-not-logged-in">
      <div class="col-1"></div>
      <div class="col-10">
        <NavbarNotLoggedIn></NavbarNotLoggedIn>
      </div>
      <div class="col-1"></div>
    </div>

    <div class="row fm-background-navbar-not-logged-in" style="padding-bottom: 3rem;">
      <div class="col-1"></div>
      <div class="col-10">
        <div class="row text-start">
          <div class="col-12">
            <p class="fm-h2">Angielski dla dzieci <br/> online</p>
          </div>
        </div>
        <div class="row text-start">
          <div class="col-6 align-middle">
            <span class="fm-text-1">Efektywna nauka angielskiego online z interaktywnym <br/> kursem
              <span class="fm-bold">FunEnglish.pl</span>
              to uczenie przez zabawę!</span>
            <br/>
            <div class="row">
              <div class="col-10">
                <ButtonActionPrimary v-on:click="this.$router.push('/pricing')"><span class="fm-button">Wypróbuj kurs <span class="fm-bold"> FunEnglish.pl</span></span></ButtonActionPrimary>
              </div>
              <div class="col-2"></div>
            </div>
          </div>
          <div class="col-6">
            <img class="img-fluid" style="height: 20rem" v-bind:src="'images/squirrel1.png'">
          </div>
        </div>
      </div>
      <div class="col-3"></div>
    </div>

    <div class="row" style="padding-top: 3rem;">
      <div class="col-1"></div>
      <div class="col-10">
        <div class="row text-start">
          <div class="col-6">
            <img class="img-fluid" style="height: 20rem;" v-bind:src="'images/boy-with-notebook.png'">
          </div>
          <div class="col-6">
            <span class="fm-text-2">
              <span class="fm-bold">FunEnglish.pl</span> to internetowy podręcznik do nauki języka angielskiego, przeznaczony dla dzieci w wieku 7-14 lat
            </span>
            <br/>
            <br/>
            <span class="fm-text-3">
              Z kursem <span class="fm-bold">FunEnglish.pl</span> nauka to zabawa!
            </span>
            <br/>
            <br/>
            <span class="fm-text-2">
              Naturalnym sposobem poznawania przez dzieci języka obcego jest nauka poprzez obraz i dźwięki. Kurs językowy FunEnglish.pl to setki multimediów! W skład każdego podrozdziału wchodzi krótki film, fotolekcja, słownik obrazkowy oraz ponad 20 ćwiczeń.
            </span>
            <br/>
            <br/>
            <span class="fm-text-2">
              Program wykorzystuje innowacyjną metodę: “immersion” (z angielskiego zanurzenie) - dzieci mają kontakt jedynie z językiem obcym. Po przejściu przez wszystkie bloki dziecko zapozna się z prawie tysiącem zwrotów przydatnych w 90% sytuacji komunikacyjnych.
            </span>
          </div>
        </div>
      </div>
      <div class="col-1"></div>
    </div>

    <div class="row" style="padding-top: 3rem;">
      <div class="col-1"></div>
      <div class="col-10">
        <div class="row text-center">
          <div class="col-12">
            <p class="fm-h1">Poznaj zalety FunEnglish</p>
          </div>
        </div>

        <div class="row text-start">
          <div class="col-3"></div>
          <div class="col-6">
            <p class="fm-text-2-2"><i class="bi-check-circle"></i> <span class="fm-bold">profesjonalizm:</span> kurs angielskiego przygotowany przez profesjonalnych nauczycieli! </p>
          </div>
          <div class="col-3">
            <img class="img-fluid" v-bind:src="'images/homepage/advantage1.png'">
          </div>
        </div>

        <div class="row text-start">
          <div class="col-3">
            <img class="img-fluid" v-bind:src="'images/homepage/advantage2.png'">
          </div>
          <div class="col-6">
            <p class="fm-text-2-2">
              <i class="bi-check-circle"></i> jako pierwszy w Polsce podręcznik internetowy <span class="fm-bold">otrzymał akceptację Ministerstwa Edukacji Narodowej</span>
            </p>
          </div>
          <div class="col-3"></div>
        </div>

        <div class="row text-start">
          <div class="col-3"></div>
          <div class="col-6">
            <p class="fm-text-2-2">
              <i class="bi-check-circle"></i> <span class="fm-bold">nagrodzony European Language Label</span> - Europejskim znakiem innowacyjności w dziedzinie nauczania języków obcych, przyznawaną przez Fundację Rozwoju Systemu Edukacji
            </p>
          </div>
          <div class="col-3">
            <img class="img-fluid" v-bind:src="'images/homepage/advantage3.png'">
          </div>
        </div>

        <div class="row text-start">
          <div class="col-3">
            <img class="img-fluid" v-bind:src="'images/homepage/advantage4.png'">
          </div>
          <div class="col-6">
            <p class="fm-text-2-2">
              <i class="bi-check-circle"></i> <span class="fm-bold">innowacja:</span> innowacyjna metoda <span class="fm-bold">FunEnglish</span>: angielska gramatyka, słownictwo i fonetyka nauczana przez kanały domniemane czyli zmysły wzroku i słuchu.
            </p>
          </div>
          <div class="col-3"></div>
        </div>

        <div class="row text-start">
          <div class="col-3"></div>
          <div class="col-6">
            <p class="fm-text-2-2">
              <i class="bi-check-circle"></i> <span class="fm-bold">nauka przez zabawę:</span> setki filmów, interaktywny słownik obrazowy i fotolekcje
            </p>
          </div>
          <div class="col-3">
            <img class="img-fluid" v-bind:src="'images/homepage/advantage5.png'">
          </div>
        </div>

        <div class="row text-start">
          <div class="col-4"></div>
          <div class="col-4">
            <ButtonActionPrimary v-on:click="this.$router.push('/pricing')"><span class="fm-button">Wypróbuj kurs <span class="fm-bold"> FunEnglish.pl</span></span></ButtonActionPrimary>
          </div>
          <div class="col-4">
          </div>
        </div>
      </div>
      <div class="col-1"></div>
      <div class="row" style="padding-top: 3rem">
        <div class="col-12">
          <img class="img-fluid" v-bind:src="'images/homepage/break.png'">
        </div>
      </div>
    </div>

    <div class="row" style="padding-top: 3rem;">
      <div class="col-1"></div>
      <div class="col-10">
        <div class="row text-center">
          <div class="col-12">
            <p class="fm-h2">Z FunEnglish Twoje dziecko nauczy się nie tylko mówić, ale również myśleć w języku angielskim</p>
          </div>
        </div>

        <div class="row text-start">
          <div class="col-2"></div>
          <div class="col-8">
            <p class="fm-text-2-2">Kurs <span class="fm-bold">FunEnglish.pl</span> jest oparty o metodę <span class="fm-bold">“Immersion”</span>, która polega na zanurzeniu dziecka w języku angielskim. Polskie dzieci nie mają możliwości stale komunikować się po angielsku w domu i w szkole. FunEnglish.pl tworzy sytuacje komunikacyjne, które wymuszają mówienie i myślenie w języku angielskim.</p>
          </div>
          <div class="col-2">
          </div>
        </div>

        <div class="row text-start">
          <div class="col-2"></div>
          <div class="col-8">
            <p class="fm-text-2-2">Ucząc się z <span class="fm-bold">FunEnglish.pl</span>, dzieci są zanurzone w języku dzięki czemu <span class="fm-bold">postępy są znacznie szybsze</span> niż w tradycyjnych metodach nauczania. Metoda ta otrzymała nie tylko grant od grona ekspertów, ale także została potwierdzona badaniami wśród rodziców uczestniczących w zajęciach z <span class="fm-bold">FunEnglish.pl</span>.</p>
          </div>
          <div class="col-2">
          </div>
        </div>

        <div class="row text-start">
          <div class="col-2"></div>
          <div class="col-8">
            <p class="fm-h2-2">93% rodziców poleca naukę z FunEnglish.pl*</p>
            <p class="fm-disclaimer">*badanie przeprowadzone na grupie 300 rodziców dzieci uczestniczących w zajęciach z FunEnglish.pl</p>
          </div>
          <div class="col-2">
          </div>
        </div>

        <br/>
        <br/>

        <div class="row text-start">
          <div class="col-4"></div>
          <div class="col-4">
            <ButtonActionPrimary v-on:click="this.$router.push('/pricing')"><span class="fm-button">Wypróbuj kurs <span class="fm-bold"> FunEnglish.pl</span></span></ButtonActionPrimary>
          </div>
          <div class="col-4">
          </div>
        </div>
      </div>
      <div class="col-1"></div>
    </div>

    <div class="row" style="padding-top: 3rem;">
      <div class="col-1"></div>
      <div class="col-10">
        <div class="row text-start">
          <div class="col-2"></div>
          <div class="col-6">
            <p class="fm-h3">Fotolekcje</p>
            <p class="fm-text-2-3">Jedną z form nauczania interaktywnego podręcznika i kursu <span class="fm-bold">FunEnglish</span> są <span class="fm-bold">fotolekcje</span>. Każde z 4000 zdjęć wykonano w najwyższej jakości. Przygotowane przez native speakerów wyrażenie dotyczą przedmiotów, miejsc i postaci znanych z życia codziennego.</p>
          </div>
          <div class="col-4">
            <img class="img-fluid" v-bind:src="'images/homepage/homepage-1.png'">
          </div>
        </div>

        <div class="row text-start">
          <div class="col-4">
            <img class="img-fluid" v-bind:src="'images/homepage/homepage-2.png'">
          </div>
          <div class="col-6">
            <p class="fm-h3">Ponad setka filmów edukacyjnych z native speakerami</p>
            <p class="fm-text-2-3">W skład Interaktywnego Podręcznika <span class="fm-bold">FunEnglish</span> wchodzi <span class="fm-bold">1000 filmów edukacyjnych w jakości HD</span>. Młodzi bohaterowie (native speakerzy) przeżywają niezwykłe przygody w magicznym świecie FunWorld.</p>
          </div>
          <div class="col-2"></div>
        </div>

        <div class="row text-start">
          <div class="col-2"></div>
          <div class="col-6">
            <p class="fm-h3">Kilkaset interaktywnych ćwiczeń językowych</p>
            <p class="fm-text-2-3">Angielskie objaśnienia gramatyczne, słownik i interaktywne ćwiczenia dodatkowo stymulują rozwój kompetencji językowych i przyswajanie wiedzy leksykalno-gramatycznej. Każda lekcja <span class="fm-bold">kompleksowo rozwija kompetencje dziecka</span> w zakresie nauki języka angielskiego. </p>
          </div>
          <div class="col-4">
            <img class="img-fluid" v-bind:src="'images/homepage/homepage-3.png'">
          </div>
        </div>
      </div>

      <div style="height: 3rem"></div>

      <div class="row text-start">
        <div class="col-4"></div>
        <div class="col-4">
          <ButtonActionPrimary v-on:click="this.$router.push('/pricing')"><span class="fm-button">Wypróbuj kurs <span class="fm-bold"> FunEnglish.pl</span></span></ButtonActionPrimary>
        </div>
        <div class="col-4">
        </div>
      </div>

      <div class="row">
        <div class="col-3"></div>
        <div class="col-6">
          <p class="fm-text-2-2 text-start">
            “FunEnglish.pl to nowoczesny i atrakcyjny podręcznik dla dzieci. Jedną z jego szczególnie ujmujących cech jest ta, że zawiera liczne filmy wideo, gry i zabawy przygotowane przez specjalistów. Interaktywne zadania odpowiednie dla tej grupy wiekowej, z pewnością będą czynnikiem motywującym do nauki języka.”
          </p>
          <p class="fm-disclaimer">PROF. DR. HAB. ANNA MICHOŃSKA-STADNIK <br/>
            RECENZENT PODRĘCZNIKÓW DLA MINISTERSTWA EDUKACJI NARODOWEJ</p>
        </div>
        <div class="col-3">
        </div>
      </div>

      <div style="height: 3rem"></div>

      <div class="row text-start">
        <div class="col-4"></div>
        <div class="col-4">
          <ButtonActionPrimary v-on:click="this.$router.push('/pricing')"><span class="fm-button">Wypróbuj kurs <span class="fm-bold"> FunEnglish.pl</span></span></ButtonActionPrimary>
        </div>
        <div class="col-4">
        </div>
      </div>

      <div style="height: 3rem"></div>

      <div class="row text-center" style="padding-top: 3rem; height: 32rem; background-color: var(--blanched-almond)">
        <div class="col-4"></div>
        <div class="col-4">
          <h2>Kontakt</h2>
          <p><strong>Email:</strong></p>
          <p>pomoc@funenglish.pl</p>
          <p><strong>Firma: </strong></p>
          <p>Funmedia</p>
          <p><strong>Adres: </strong></p>
          <p>InQube Building</p>
          <p>ul. Wielka 67, 53-340 Wrocław</p>
          <p class="fm-disclaimer">angielski dla dzieci | © 2022 FunEnglish.pl</p>
        </div>
        <div class="col-4">
        </div>
      </div>

      <div class="col-1"></div>
    </div>
  </template>

  <template v-if="deviceType === 'mobile'">
    <div class="row fm-background-navbar-not-logged-in">
      <div class="col-1"></div>
      <div class="col-10">
        <NavbarNotLoggedIn></NavbarNotLoggedIn>
      </div>
      <div class="col-1"></div>
    </div>

    <div class="row fm-background-navbar-not-logged-in" style="padding-bottom: 3rem;">
      <div class="col-1"></div>
      <div class="col-10">
        <div class="row text-start">
          <div class="col-12">
            <p class="fm-mobile-h2">Angielski dla dzieci <br/> online</p>
          </div>
        </div>
        <div class="row text-start">
          <div class="col-md-6 align-middle">
            <span class="fm-text-1">Efektywna nauka angielskiego online z interaktywnym <br/> kursem
              <span class="fm-bold">FunEnglish.pl</span>
              to uczenie przez zabawę!</span>
            <br/>
            <div style="height: 3rem"></div>
            <div class="row">
              <div class="col-12">
                <ButtonActionPrimary v-on:click="this.$router.push('/pricing')"><span class="fm-button">Wypróbuj kurs <span class="fm-bold"> FunEnglish.pl</span></span></ButtonActionPrimary>
              </div>
            </div>
          </div>
          <div style="height: 2rem"></div>
          <div class="col-md-6">
            <img class="img-fluid" style="height: 20vh" v-bind:src="'images/squirrel1.png'">
          </div>
        </div>
      </div>
      <div class="col-3"></div>
    </div>

    <div class="row" style="padding-top: 3rem;">
      <div class="col-1"></div>
      <div class="col-10">
        <div class="row text-start">
          <div class="col-md-6">
            <span class="fm-text-2">
              <span class="fm-bold">FunEnglish.pl</span> to internetowy podręcznik do nauki języka angielskiego, przeznaczony dla dzieci w wieku 7-14 lat
            </span>
            <br/>
            <br/>
            <span class="fm-text-3">
              Z kursem <span class="fm-bold">FunEnglish.pl</span> nauka to zabawa!
            </span>
            <br/>
            <br/>
            <span class="fm-text-2">
              Naturalnym sposobem poznawania przez dzieci języka obcego jest nauka poprzez obraz i dźwięki. Kurs językowy FunEnglish.pl to setki multimediów! W skład każdego podrozdziału wchodzi krótki film, fotolekcja, słownik obrazkowy oraz ponad 20 ćwiczeń.
            </span>
            <br/>
            <br/>
            <span class="fm-text-2">
              Program wykorzystuje innowacyjną metodę: “immersion” (z angielskiego zanurzenie) - dzieci mają kontakt jedynie z językiem obcym. Po przejściu przez wszystkie bloki dziecko zapozna się z prawie tysiącem zwrotów przydatnych w 90% sytuacji komunikacyjnych.
            </span>
          </div>
          <div class="col-md-6">
            <img class="img-fluid" style="height: 20rem;" v-bind:src="'images/boy-with-notebook.png'">
          </div>
        </div>
      </div>
      <div class="col-1"></div>
    </div>

    <div class="row" style="padding-top: 3rem;">
      <div class="col-1"></div>
      <div class="col-10">
        <div class="row text-center">
          <div class="col-12">
            <p class="fm-h1">Poznaj zalety FunEnglish</p>
          </div>
        </div>

        <div class="row text-center">
          <div class="col-2"></div>
          <div class="col-8">
            <img class="img-fluid" v-bind:src="'images/homepage/advantage1.png'">
          </div>
          <div class="col-2"></div>
        </div>
        <div class="row text-start">
          <div class="col-12">
            <p class="fm-text-2-2"><i class="bi-check-circle"></i> <span class="fm-bold">profesjonalizm:</span> kurs angielskiego przygotowany przez profesjonalnych nauczycieli! </p>
          </div>
        </div>

        <div class="row text-center">
          <div class="col-2"></div>
          <div class="col-8">
            <img class="img-fluid" v-bind:src="'images/homepage/advantage2.png'">
          </div>
          <div class="col-2"></div>
        </div>
        <div class="row text-start">
          <div class="col-12">
            <p class="fm-text-2-2">
              <i class="bi-check-circle"></i> jako pierwszy w Polsce podręcznik internetowy <span class="fm-bold">otrzymał akceptację Ministerstwa Edukacji Narodowej</span>
            </p>
          </div>
        </div>

        <div class="row text-center">
          <div class="col-2"></div>
          <div class="col-8">
            <img class="img-fluid" v-bind:src="'images/homepage/advantage3.png'">
          </div>
          <div class="col-2"></div>
        </div>
        <div class="row text-start">
          <div class="col-12">
            <p class="fm-text-2-2">
              <i class="bi-check-circle"></i> <span class="fm-bold">nagrodzony European Language Label</span> - Europejskim znakiem innowacyjności w dziedzinie nauczania języków obcych, przyznawaną przez Fundację Rozwoju Systemu Edukacji
            </p>
          </div>
        </div>

        <div class="row text-center">
          <div class="col-2"></div>
          <div class="col-8">
            <img class="img-fluid" v-bind:src="'images/homepage/advantage4.png'">
          </div>
          <div class="col-2"></div>
        </div>
        <div class="row text-start">
          <div class="col-12">
            <p class="fm-text-2-2">
              <i class="bi-check-circle"></i> <span class="fm-bold">innowacja:</span> innowacyjna metoda <span class="fm-bold">FunEnglish</span>: angielska gramatyka, słownictwo i fonetyka nauczana przez kanały domniemane czyli zmysły wzroku i słuchu.
            </p>
          </div>
        </div>

        <div class="row text-center">
          <div class="col-2"></div>
          <div class="col-8">
            <img class="img-fluid" v-bind:src="'images/homepage/advantage5.png'">
          </div>
          <div class="col-2"></div>
        </div>
        <div class="row text-start">
          <div class="col-12">
            <p class="fm-text-2-2">
              <i class="bi-check-circle"></i> <span class="fm-bold">nauka przez zabawę:</span> setki filmów, interaktywny słownik obrazowy i fotolekcje
            </p>
          </div>
        </div>

        <div class="row text-start">
          <div class="col-12">
            <ButtonActionPrimary v-on:click="this.$router.push('/pricing')"><span class="fm-button">Wypróbuj kurs <span class="fm-bold"> FunEnglish.pl</span></span></ButtonActionPrimary>
          </div>
        </div>
      </div>
      <div class="col-1"></div>
      <div class="row" style="padding-top: 3rem">
        <div class="col-12">
          <img class="img-fluid" v-bind:src="'images/homepage/break.png'">
        </div>
      </div>
    </div>

    <div class="row" style="padding-top: 3rem;">
      <div class="col-1"></div>
      <div class="col-10">
        <div class="row text-start">
          <div class="col-12">
            <p class="fm-mobile-h2">Z FunEnglish Twoje dziecko nauczy się nie tylko mówić, ale również myśleć w języku angielskim</p>
          </div>
        </div>

        <div class="row text-start">
          <div class="col-12">
            <p class="fm-text-2-2">Kurs <span class="fm-bold">FunEnglish.pl</span> jest oparty o metodę <span class="fm-bold">“Immersion”</span>, która polega na zanurzeniu dziecka w języku angielskim. Polskie dzieci nie mają możliwości stale komunikować się po angielsku w domu i w szkole. FunEnglish.pl tworzy sytuacje komunikacyjne, które wymuszają mówienie i myślenie w języku angielskim.</p>
          </div>
        </div>

        <div class="row text-start">
          <div class="col-12">
            <p class="fm-text-2-2">Ucząc się z <span class="fm-bold">FunEnglish.pl</span>, dzieci są zanurzone w języku dzięki czemu <span class="fm-bold">postępy są znacznie szybsze</span> niż w tradycyjnych metodach nauczania. Metoda ta otrzymała nie tylko grant od grona ekspertów, ale także została potwierdzona badaniami wśród rodziców uczestniczących w zajęciach z <span class="fm-bold">FunEnglish.pl</span>.</p>
          </div>
        </div>

        <div class="row text-center">
          <div class="col-12">
            <p class="fm-h2-2">93% rodziców poleca naukę z FunEnglish.pl*</p>
            <p class="fm-disclaimer">*badanie przeprowadzone na grupie 300 rodziców dzieci uczestniczących w zajęciach z FunEnglish.pl</p>
          </div>
        </div>

        <br/>
        <br/>

        <div class="row text-start">
          <div class="col-12">
            <ButtonActionPrimary v-on:click="this.$router.push('/pricing')"><span class="fm-button">Wypróbuj kurs <span class="fm-bold"> FunEnglish.pl</span></span></ButtonActionPrimary>
          </div>
        </div>
      </div>
      <div class="col-1"></div>
    </div>

    <div class="row" style="padding-top: 3rem;">
      <div class="col-12">
        <div class="row text-start">
          <div class="col-1"></div>
          <div class="col-10">
            <p class="fm-h3">Fotolekcje</p>
            <p class="fm-text-2-3">Jedną z form nauczania interaktywnego podręcznika i kursu <span class="fm-bold">FunEnglish</span> są <span class="fm-bold">fotolekcje</span>. Każde z 4000 zdjęć wykonano w najwyższej jakości. Przygotowane przez native speakerów wyrażenie dotyczą przedmiotów, miejsc i postaci znanych z życia codziennego.</p>
          </div>
          <div class="col-1"></div>
        </div>
        <div class="row text-start">
          <div class="col-1"></div>
          <div class="col-10">
            <img class="img-fluid" v-bind:src="'images/homepage/homepage-1.png'">
          </div>
          <div class="col-1"></div>
        </div>

        <div class="row text-start">
          <div class="col-1"></div>
          <div class="col-10">
            <p class="fm-h3">Ponad setka filmów edukacyjnych z native speakerami</p>
            <p class="fm-text-2-3">W skład Interaktywnego Podręcznika <span class="fm-bold">FunEnglish</span> wchodzi <span class="fm-bold">1000 filmów edukacyjnych w jakości HD</span>. Młodzi bohaterowie (native speakerzy) przeżywają niezwykłe przygody w magicznym świecie FunWorld.</p>
          </div>
          <div class="col-1"></div>
        </div>
        <div class="row text-start">
          <div class="col-1"></div>
          <div class="col-10">
            <img class="img-fluid" v-bind:src="'images/homepage/homepage-2.png'">
          </div>
          <div class="col-1"></div>
        </div>

        <div class="row text-start">
          <div class="col-1"></div>
          <div class="col-10">
            <p class="fm-h3">Kilkaset interaktywnych ćwiczeń językowych</p>
            <p class="fm-text-2-3">Angielskie objaśnienia gramatyczne, słownik i interaktywne ćwiczenia dodatkowo stymulują rozwój kompetencji językowych i przyswajanie wiedzy leksykalno-gramatycznej. Każda lekcja <span class="fm-bold">kompleksowo rozwija kompetencje dziecka</span> w zakresie nauki języka angielskiego. </p>
          </div>
          <div class="col-1"></div>
        </div>
        <div class="row text-start">
          <div class="col-1"></div>
          <div class="col-10">
            <img class="img-fluid" v-bind:src="'images/homepage/homepage-3.png'">
          </div>
          <div class="col-1"></div>
        </div>
      </div>

      <div style="height: 3rem"></div>

      <div class="row text-start">
        <div class="col-1"></div>
        <div class="col-10">
          <ButtonActionPrimary v-on:click="this.$router.push('/pricing')"><span class="fm-button">Wypróbuj kurs <span class="fm-bold"> FunEnglish.pl</span></span></ButtonActionPrimary>
        </div>
        <div class="col-1"></div>
      </div>

      <div class="row">
        <div class="col-1"></div>
        <div class="col-10">
          <p class="fm-text-2-2 text-start">
            “FunEnglish.pl to nowoczesny i atrakcyjny podręcznik dla dzieci. Jedną z jego szczególnie ujmujących cech jest ta, że zawiera liczne filmy wideo, gry i zabawy przygotowane przez specjalistów. Interaktywne zadania odpowiednie dla tej grupy wiekowej, z pewnością będą czynnikiem motywującym do nauki języka.”
          </p>
          <p class="fm-disclaimer">PROF. DR. HAB. ANNA MICHOŃSKA-STADNIK <br/>
            RECENZENT PODRĘCZNIKÓW DLA MINISTERSTWA EDUKACJI NARODOWEJ</p>
        </div>
        <div class="col-1">
        </div>
      </div>

      <div style="height: 3rem"></div>

      <div class="row text-start">
        <div class="col-1"></div>
        <div class="col-10">
          <ButtonActionPrimary v-on:click="this.$router.push('/pricing')"><span class="fm-button">Wypróbuj kurs <span class="fm-bold"> FunEnglish.pl</span></span></ButtonActionPrimary>
        </div>
        <div class="col-1">
        </div>
      </div>

      <div style="height: 3rem"></div>

      <div class="row text-center" style="padding-top: 3rem; height: 32rem; background-color: var(--blanched-almond)">
        <div class="col-1"></div>
        <div class="col-10">
          <h2>Kontakt</h2>
          <p><strong>Email:</strong></p>
          <p>pomoc@funenglish.pl</p>
          <p><strong>Firma: </strong></p>
          <p>Funmedia</p>
          <p><strong>Adres: </strong></p>
          <p>InQube Building</p>
          <p>ul. Wielka 67, 53-340 Wrocław</p>
          <p class="fm-disclaimer">angielski dla dzieci | © 2022 FunEnglish.pl</p>
        </div>
        <div class="col-1">
        </div>
      </div>
    </div>
  </template>
  </div>
</template>

<script>
// @ is an alias to /src
import NavbarNotLoggedIn from '../components/NavbarNotLoggedIn';
import ButtonActionPrimary from '../components/Utils/Atom/ButtonActionPrimary';

export default {
  name: 'Home',
  components: {
    ButtonActionPrimary,
    NavbarNotLoggedIn
  },
  data() {
    return {
      deviceType: 'desktop'
    };
  },
  mounted() {
    window.addEventListener('resize', this.onResize);
    this.setDeviceType();
  },
  methods: {
    onResize() {
      this.setDeviceType();
    },
    setDeviceType() {
      if (window.outerWidth > 576) {
        this.deviceType = 'desktop';
      } else {
        this.deviceType = 'mobile';
      }
    }
  }
};
</script>

<style>
.fm-background-navbar-not-logged-in {
  background-color: var(--blanched-almond);
}

.fm-h1 {
  color: var(--navy-blue);
  font-size: 5vh;
  font-weight: 800;
  margin-top: 4rem;
}

.fm-h2 {
  color: var(--navy-blue);
  font-size: 3rem;
  font-weight: 800;
  margin-top: 4rem;
}

.fm-mobile-h2 {
  color: var(--navy-blue);
  font-size: 3vh;
  font-weight: 800;
  margin-top: 4rem;
}

.fm-h2-2 {
  color: var(--royal-orange);
  font-size: 3rem;
  font-weight: 800;
  margin-top: 4rem;
}

.fm-h3 {
  color: var(--navy-blue);
  font-size: 2rem;
  font-weight: 800;
  margin-top: 4rem;
}

.fm-text-1 {
  color: var(--navy-blue);
  font-size: 2.5vh;
  font-weight: 400;
  margin-top: 8rem;
}

.fm-text-2 {
  color: var(--navy-blue);
  font-size: 1.5rem;
  font-weight: 400;
  margin-top: 8rem;
}

.fm-text-2-2 {
  color: var(--navy-blue);
  font-size: 1.5rem;
  font-weight: 400;
  margin-top: 6rem;
}

.fm-text-2-3 {
  color: var(--navy-blue);
  font-size: 1.5rem;
  font-weight: 400;
  margin-top: 2rem;
}

.fm-text-3 {
  color: var(--royal-orange);
  font-size: 1.5rem;
  font-weight: 400;
  margin-top: 8rem;
}

.fm-bold {
  font-weight: 800;
}

.fm-disclaimer {
  color: var(--navy-blue);
  font-size: 0.75rem;
}

.fm-background-2 {
  background-color: white;
}

.fm-button {
  font-size: 1.5rem;
}
</style>
