export class CookieService {
  // TODO: set expired and http only, later for https set also secure flag
  static setCookie(name, value) {
    // let expires = "";
    //
    // if (days) {
    //   let date = new Date();
    //   date.setTime(date.getTime() + (days*24*60*60*1000));
    //   expires = "; expires=" + date.toUTCString();
    // }
    // document.cookie = name + "=" + (value || "")  + expires + "; path=/";
    document.cookie = name + '=' + (value || '') + '; path=/';
  }

  static getCookie(name) {
    const nameEQ = name + '=';
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }
}

const cookieService = CookieService;
export default cookieService;
