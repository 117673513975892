<template>
  <template v-if="deviceType === 'desktop'">
    <div class="row fm-background-main-color-desktop">
      <div class="col-1"></div>
      <div class="col-10">
        <NavbarNotLoggedIn></NavbarNotLoggedIn>
      </div>
      <div class="col-1"></div>
    </div>
    <div class="container-fluid vh-100 fm-background-main-color-desktop">

<!--      <div class="row">-->
<!--        <div class="col-md-4"></div>-->
<!--        <div class="col-md-4 fm-background-main-color">-->
<!--          <div class="fixed-top">-->
<!--            <img v-bind:src="'images/clouds.svg'" class="img-fluid fm-100-width-image fm-header-image">-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="col-md-4"></div>-->
<!--      </div>-->

<!--      <div class="row">-->
<!--        <div class="col-md-4"></div>-->
<!--        <div class="col-md-4 fm-background-main-color-desktop">-->
<!--          <img v-bind:src="'images/funenglish-logo.svg'" class="img-fluid fm-logo-image">-->
<!--        </div>-->
<!--        <div class="col-md-4"></div>-->
<!--      </div>-->

      <div class="row">
        <div class="col-md-4"></div>
        <div class="col-md-4 fm-main-label fm-label-main-color fm-background-main-color-desktop">Zaloguj się</div>
        <div class="col-md-4"></div>
      </div>

      <div class="row">
        <div class="col-md-4"></div>
        <div class="col-md-4 fm-z-index-10 fm-background-main-color-desktop">
          <form @submit.prevent="login">
            <div class="mb-4">
              <input v-model="userIdentity" type="text" class="form-control shadow-none" id="userIdentity" placeholder="Login lub email">
            </div>
            <InputPassword @password-changed="(providedPassword) => updatePasswordValue(providedPassword)"></InputPassword>
            <template v-if="errorMessage">
              <div class="row text-start">
                <div class="col-md-12 fm-error-message">Niepoprawny login/email lub hasło</div>
              </div>
            </template>
            <template v-if="isSessionExpired">
              <div class="row text-start">
                <div class="col-md-12 fm-error-message">Sesja wygasła, zaloguj się ponownie</div>
              </div>
            </template>
            <ButtonPrimary :button-text="'Zaloguj się'" :type="'submit'"></ButtonPrimary>
          </form>
          <ButtonPrimaryOutline :button-text="'Zapomniałem hasła'" :role="'button'" :href="'forgot-password'"></ButtonPrimaryOutline>
        </div>
        <div class="col-md-4"></div>
      </div>

<!--      <div class="row">-->
<!--        <div class="col-md-3"></div>-->
<!--        <div class="col-md-6 fm-z-index-0">-->
<!--          <div class="fixed-bottom">-->
<!--            <img v-bind:src="'images/sun.svg'" class="fm-100-width-image img-fluid" style="position: fixed; bottom: 0; right: 0;">-->
<!--            <img v-bind:src="'images/squirrel.svg'" style="position: fixed; bottom: 0; right: 0;">-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="col-md-3"></div>-->
<!--      </div>-->

    </div>
  </template>
  <template v-if="deviceType === 'mobile'">
    <div class="container vh-100 fm-background-main-color">

      <div class="row">
        <div class="col-md-3"></div>
        <div class="col-md-6">
          <div class="fixed-top">
            <img v-bind:src="'images/clouds.svg'" class="img-fluid fm-100-width-image fm-header-image">
          </div>
        </div>
        <div class="col-md-3"></div>
      </div>

      <div class="row">
        <div class="col-md-3"></div>
        <div class="col-md-6">
          <img v-bind:src="'images/funenglish-logo.svg'" class="img-fluid fm-logo-image" v-on:click="redirectToMainPage()">
        </div>
        <div class="col-md-3"></div>
      </div>

      <div class="row">
        <div class="col-md-3"></div>
        <div class="col-md-6 fm-main-label fm-label-main-color">Zaloguj się</div>
        <div class="col-md-3"></div>
      </div>

      <div class="row">
        <div class="col-md-3"></div>
        <div class="col-md-6 fm-z-index-10">
          <form @submit.prevent="login">
            <div class="mb-3">
              <input v-model="userIdentity" type="text" class="form-control shadow-none" id="userIdentity" placeholder="Login lub email">
            </div>
            <InputPassword @password-changed="(providedPassword) => updatePasswordValue(providedPassword)"></InputPassword>
            <template v-if="errorMessage">
              <div class="row text-start">
                <div class="col-md-12 fm-error-message">Niepoprawny login/email lub hasło</div>
              </div>
            </template>
            <template v-if="isSessionExpired">
              <div class="row text-start">
                <div class="col-md-12 fm-error-message">Sesja wygasła, zaloguj się ponownie</div>
              </div>
            </template>
            <ButtonPrimary :button-text="'Zaloguj się'" :type="'submit'"></ButtonPrimary>
          </form>
          <ButtonPrimaryOutline :button-text="'Zapomniałem hasła'" :role="'button'" :href="'forgot-password'"></ButtonPrimaryOutline>
        </div>
        <div class="col-md-3"></div>
      </div>

      <div class="row">
        <div class="col-md-3"></div>
        <div class="col-md-6 fm-z-index-0">
          <div class="fixed-bottom">
            <img v-bind:src="'images/sun.svg'" class="fm-100-width-image img-fluid" style="position: fixed; bottom: 0; right: 0;">
            <img v-bind:src="'images/squirrel.svg'" style="position: fixed; bottom: 0; right: 0;">
          </div>
        </div>
        <div class="col-md-3"></div>
      </div>

    </div>
    </template>
</template>

<script>
import cookieService from '../services/cookieService.js';
import loginService from '../services/loginService';
import ButtonPrimary from '../components/Utils/Atom/ButtonPrimary';
import ButtonPrimaryOutline from '../components/Utils/Atom/ButtonPrimaryOutline';
import InputPassword from '../components/Utils/Atom/InputPassword';
import NavbarNotLoggedIn from '../components/NavbarNotLoggedIn';

export default {
  name: 'Login',
  components: { NavbarNotLoggedIn, InputPassword, ButtonPrimaryOutline, ButtonPrimary },
  data() {
    return {
      userIdentity: '',
      password: '',
      url: process.env.DOMAIN,
      errorMessage: '',
      isSessionExpired: false,
      deviceType: 'desktop'
    };
  },
  mounted() {
    this.redirectLoggedInUserToFunBook();
    this.isSessionExpired = this.$route.query.isSessionExpired;
    window.addEventListener('resize', this.onResize);
    this.setDeviceType();
  },
  unmounted() {
    window.addEventListener('resize', this.onResize);
  },
  methods: {
    onResize() {
      this.setDeviceType();
    },
    setDeviceType() {
      if (window.outerWidth > 576) {
        this.deviceType = 'desktop';
      } else {
        this.deviceType = 'mobile';
      }
    },
    async login() {
      const response = await loginService.login(this.userIdentity, this.password);

      if (response.data.isSuccess === true) {
        cookieService.setCookie('token', response.data.token);
        this.$store.commit('setIsUserLoggedIn', true);
        await this.$router.push('FunBook');
      } else {
        this.errorMessage = response.data.message;
      }
    },
    updatePasswordValue(password) {
      this.password = password;
    },
    async redirectLoggedInUserToFunBook() {
      const token = cookieService.getCookie('token');

      if (token) {
        await this.$router.push('FunBook');
      }
    },
    redirectToMainPage() {
      this.$router.push('/');
    }
  }
};
</script>

<style scoped>
  .fm-z-index-0 {
    z-index: 0;
  }

  .fm-z-index-10 {
    z-index: 10;
  }

  .fm-header-image {
    padding-top: 5vh;
  }

  .fm-logo-image {
    padding-top: 12vh;
  }

  .fm-100-width-image {
    min-width: calc(100% + 24px);
    margin-left: -12px;
  }

  .fm-error-message {
    color: #DA3749;
    font-size: 0.75rem;
    margin-top: -0.75rem;
  }

  .fm-background-main-color-desktop {
    background-color: var(--blanched-almond);
  }
</style>
