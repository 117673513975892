<template>
  <template v-if="deviceType === 'desktop'">
    <div class="row">
      <div class="col-2 fm-navbar-not-loggged-in">
        <img class="img-fluid fm-image" src="../../public/images/funenglish-logo.svg" v-on:click="redirectToMainPage()">
      </div>
      <div class="col-3 fm-navbar-not-loggged-in"></div>
      <div class="col-1 fm-navbar-not-loggged-in text-end">
        <a id="navbar-option-1"
           href="/pricing"
           class=" fm-navbar-option fm-navbar-option">Cennik</a>
      </div>
      <div class="col-1 fm-navbar-not-loggged-in text-end">
        <a id="navbar-option-2"
           href="/login"
           class="fm-navbar-option fm-navbar-option">Logowanie</a>
      </div>
      <div class="col-2 fm-navbar-not-loggged-in text-center">
        <button v-on:click="this.$router.push('/code')" type="button" class="btn btn-outline-primary">Aktywuj kupon</button>
      </div>
      <div class="col-2 fm-navbar-not-loggged-in text-start">
        <button v-on:click="this.$router.push('/register')" type="button" class="btn btn-primary">Rejestracja</button>
      </div>
      <div class="col-1 fm-navbar-not-loggged-in"></div>
    </div>
  </template>

  <template v-if="deviceType === 'mobile'">
    <div class="row">
      <div class="col-md-3"></div>
      <div class="col-md-6 fm-navbar">
        <div class="row text-start">
          <div class="col-7">
            <img class="img-fluid" src="../../public/images/funenglish-logo.svg" v-on:click="redirectToMainPage()">
          </div>
          <div class="col-3"></div>
          <div class="col-2 text-end">
            <a data-bs-toggle="offcanvas" href="#offcanvasExample" aria-controls="offcanvasExample">
              <i class="bi bi-list fm-list-menu"></i>
            </a>
          </div>
        </div>
      </div>
      <div class="col-md-3"></div>
    </div>

    <MenuNotLoggedIn></MenuNotLoggedIn>
  </template>

</template>

<script>
import MenuNotLoggedIn from './MenuNotLoggedIn';

export default {
  name: 'NavbarNotLoggedIn',
  components: { MenuNotLoggedIn },
  props: {
    title: String
  },
  data() {
    return {
      deviceType: 'desktop'
    };
  },
  mounted() {
    window.addEventListener('resize', this.onResize);
    this.setDeviceType();

    if (this.deviceType === 'desktop') {
      this.setActiveNavbarOption();
    }
  },
  unmounted() {
    window.addEventListener('resize', this.onResize);
  },
  methods: {
    onResize() {
      this.setDeviceType();
    },
    setDeviceType() {
      if (window.outerWidth > 576) {
        this.deviceType = 'desktop';
      } else {
        this.deviceType = 'mobile';
      }
    },
    // TODO: This method should be unified with same method in Menu component
    setActiveNavbarOption() {
      const fullPath = this.$route.fullPath;
      const splittedFullPath = fullPath.split('/');
      const firstPartOfFullPath = splittedFullPath[1];
      const activeNavbarElement = document.querySelector('a[href^="/' + firstPartOfFullPath + '"]');

      if (activeNavbarElement) {
        activeNavbarElement.classList.remove('fm-navbar-inactive-option');
        activeNavbarElement.classList.add('fm-navbar-active-option');
      }
    },
    redirectToMainPage() {
      this.$router.push('/');
    }
  }
};
</script>

<style scoped>
  .fm-navbar-not-loggged-in {
    background-color: var(--blanched-almond);
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    font-size: 1.25rem;
    font-weight: 700;
    color: var(--navy-blue);
  }

  .fm-navbar-option {
    color: var(--navy-blue);
  }

  .fm-list-menu {
    color: #F8F9FA;
  }

  .fm-navbar-option {
    font-size: 1rem;
    font-weight: 700;
    padding-top: 1.25rem;
    text-decoration: none;
  }

  .fm-navbar-option:hover {
    cursor: pointer;
  }

  .fm-image {
    cursor: pointer;
  }

  .fm-navbar {
    background-color: var(--blanched-almond);
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    font-size: 1.25rem;
    font-weight: 700;
    color: #F8F9FA;
  }

  .fm-list-menu {
    color: black;
    font-size: 2rem;
  }
</style>
