<template>
  <button class="btn btn-primary w-100 fm-login-button fm-button-main-background-color fm-exercise-next-button">
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: 'ButtonActionPrimary'
};
</script>

<style scoped>

</style>
