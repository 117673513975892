<template>
<!--  <div id="nav">-->
<!--    <router-link to="/">Home</router-link> |-->
<!--    <router-link to="/about">About</router-link> |-->
<!--    <template v-if="!isUserLoggedIn">-->
<!--      <router-link to="/login">Login</router-link> |-->
<!--      <router-link to="/register">Register</router-link>-->
<!--    </template>-->
<!--    <template v-if="isUserLoggedIn">-->
<!--      <router-link to="/" v-on:click="logout">Logout</router-link>-->
<!--    </template>-->
<!--    <br/>-->
<!--    <template v-if="isUserLoggedIn">-->
<!--      <router-link to="/dictionary">Dictionary</router-link> |-->
<!--      <router-link to="/units">Units</router-link>-->
<!--    </template>-->
<!--  </div>-->
  <router-view/>
</template>

<script>
import cookieService from './services/cookieService';

export default {
  name: 'App',
  computed: {
    isUserLoggedIn: function() {
      return this.$store.state.isUserLoggedIn;
    }
  },
  methods: {
    logout() {
      cookieService.setCookie('token', null);
      this.$store.commit('setIsUserLoggedIn', false);
    }
  }
};
</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
