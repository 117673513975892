<template>
  <div class="offcanvas offcanvas-end w-75" tabindex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
    <div class="offcanvas-header">
      <h5 class="offcanvas-title fm-menu-title" id="offcanvasExampleLabel">Menu</h5>
      <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
    </div>
    <div class="offcanvas-body">

      <nav class="navbar  ">
        <div class="container">
          <div class="w-100 text-start" id="navbarNav">
            <ul class="navbar-nav">
              <li class="nav-item">
                <a class="nav-link fm-navbar-item-inactive p-2" v-on:click="setActiveMenuItem('homepage')" id="homepage" href="/">Strona główna</a>
              </li>
              <li class="nav-item">
                <a class="nav-link fm-navbar-item-inactive p-2" v-on:click="setActiveMenuItem('pricing')" id="pricing" href="/pricing">Cennik</a>
              </li>
              <li class="nav-item">
                <a class="nav-link fm-navbar-item-inactive p-2" v-on:click="setActiveMenuItem('login')" id="login" href="/login">Logowanie</a>
              </li>
              <li class="nav-item">
                <a class="nav-link fm-navbar-item-inactive p-2" v-on:click="setActiveMenuItem('code')" id="code" href="/code">Aktywuj kupon</a>
              </li>
              <li class="nav-item">
                <a class="nav-link fm-navbar-item-inactive p-2" v-on:click="setActiveMenuItem('register')" id="register" href="/register">Rejestracja</a>
              </li>
            </ul>
          </div>
        </div>
      </nav>

    </div>
  </div>
</template>

<script>
export default {
  name: 'MenuNotLoggedIn',
  mounted() {
    this.setActiveMenuItem();
  },
  methods: {
    setActiveMenuItem() {
      const fullPath = this.$route.fullPath;
      const splittedFullPath = fullPath.split('/');
      const firstPartOfFullPath = splittedFullPath[1];
      const activeNavbarElement = document.querySelector('a[href^="/' + firstPartOfFullPath + '"]');

      if (activeNavbarElement) {
        activeNavbarElement.classList.remove('fm-navbar-item');
        activeNavbarElement.classList.add('fm-navbar-item-active');
      }
    }
  }
};
</script>

<style scoped>
  .fm-menu-title {
    color: var(--navy-blue);
    font-size: 1.5rem;
    font-weight: bold;
  }

  .fm-navbar-item-inactive {
    color: var(--navy-blue);
  }

  .fm-navbar-item-active {
    background-color: var(--navy-blue);
    color: white;
  }
</style>
