import { createApp } from 'vue';
import { createStore } from 'vuex';
import App from './App.vue';
import router from './router';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'bootstrap';
import '../public/css/main.css';
import cookieService from './services/cookieService';

const store = createStore({
  state() {
    return {
      isUserLoggedIn: (cookieService.getCookie('token') && (cookieService.getCookie('token').length > 0))
    };
  },
  mutations: {
    setIsUserLoggedIn(state, isUserLoggedIn) {
      state.isUserLoggedIn = isUserLoggedIn;
    }
  }
});

const app = createApp(App);

app.use(store);
app.use(router);
app.mount('#app');
