<template>
  <template v-if="type">
    <button v-bind:type="type" class="btn btn-primary w-100 fm-login-button fm-button-main-background-color">{{ buttonText }}</button>
  </template>
  <template v-if="role">
    <a v-bind:role="role" v-bind:href="href" class="btn btn-primary w-100 fm-login-button fm-button-main-background-color">{{ buttonText }}</a>
  </template>
</template>

<script>
export default {
  name: 'ButtonPrimary',
  props: {
    buttonText: String,
    type: {
      type: String,
      default: null
    },
    href: {
      type: String,
      default: null
    },
    role: {
      type: String,
      default: null
    }
  }
};
</script>

<style scoped>
.fm-login-button {
  margin-top: 1.5rem;
  font-size: 1.25rem;
}

.fm-button-main-background-color {
  background-color: var(--dodger-blue);
}

.fm-button-main-background-color:hover {
  background-color: var(--dodger-blue);
}

</style>
