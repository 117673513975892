import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Home.vue';
import Login from '../views/Login.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/pricing',
    name: 'Pricing',
    component: () => import('../views/Pricing')
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: () => import('../views/ForgotPassword')
  },
  {
    path: '/expired-subscription',
    name: 'ExpiredSubscription',
    component: () => import('../views/ExpiredSubscription')
  },
  {
    path: '/unknown-error',
    name: 'UnknownError',
    component: () => import('../views/UnknownError')
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('../views/Register')
  },
  {
    path: '/code',
    name: 'Code',
    component: () => import('../views/Code')
  },
  {
    path: '/dictionary',
    name: 'WordList',
    component: () => import('../views/Dictionary/WordList')
  },
  {
    path: '/dictionary/word/:id',
    name: 'WordDetails',
    component: () => import('../views/Dictionary/WordDetails')
  },
  {
    path: '/funbook',
    name: 'FunBook',
    component: () => import('../views/FunBook')
  },
  {
    path: '/funbook/lesson/list/:id',
    name: 'LessonList',
    component: () => import('../views/FunBook/LessonList')
  },
  {
    path: '/funbook/lesson/:id',
    name: 'Lesson',
    component: () => import('../views/FunBook/Lesson')
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import('../views/Profile')
  },
  {
    path: '/change-password',
    name: 'ChangePassword',
    component: () => import('../views/ChangePassword')
  },
  {
    path: '/payment/result',
    name: 'PaymentResult',
    component: () => import('../views/PaymentResult')
  },
  {
    path: '/payment/success',
    name: 'PaymentSuccess',
    component: () => import('../views/PaymentSuccess')
  },
  {
    path: '/payment/failure',
    name: 'PaymentFailure',
    component: () => import('../views/PaymentFailure')
  },
  {
    path: '/admin',
    name: 'Admin',
    component: () => import('../views/admin/Admin'),
    children: [
      {
        path: 'sales-package/new',
        name: 'SalesPackageNew',
        component: () => import('../views/admin/SalesPackage/SalesPackage')
      },
      {
        path: 'sales-package/:id',
        name: 'SalesPackageDetails',
        component: () => import('../views/admin/SalesPackage/SalesPackage')
      },
      {
        path: 'sales-package/:id/codes',
        name: 'ManageCodes',
        component: () => import('../views/admin/SalesPackage/ManageCodes')
      },
      {
        path: 'sales-package/list',
        name: 'SalesPackageList',
        component: () => import('../views/admin/SalesPackage/SalesPackageList')
      },
      {
        path: 'sales-package/code/check',
        name: 'CheckCode',
        component: () => import('../views/admin/SalesPackage/CheckCode')
      },
      {
        path: 'partner/new',
        name: 'PartnerNew',
        component: () => import('../views/admin/Partner/Partner')
      },
      {
        path: 'partner/:id',
        name: 'PartnerDetails',
        component: () => import('../views/admin/Partner/Partner')
      },
      {
        path: 'partner/list',
        name: 'PartnerList',
        component: () => import('../views/admin/Partner/PartnerList')
      },
      {
        path: 'payment/list',
        name: 'PaymentList',
        component: () => import('../views/admin/Payment/PaymentList')
      },
      {
        path: 'user/users',
        name: 'Users',
        component: () => import('../views/admin/User/Users')
      },
      {
        path: 'user/new',
        name: 'UserDetailsNew',
        component: () => import('../views/admin/User/User')
      },
      {
        path: 'user/:id',
        name: 'UserDetails',
        component: () => import('../views/admin/User/User')
      },
      {
        path: 'user/list',
        name: 'UserList',
        component: () => import('../views/admin/User/UserList')
      }
    ]
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
