<template>
  <template v-if="type">
    <button v-bind:type="type" class="btn btn-outline-primary w-100 fm-forget-password-button fm-button-main-border-color">{{ buttonText }}</button>
  </template>
  <template v-if="role">
    <a v-bind:role="role" v-bind:href="href" class="btn btn-outline-primary w-100 fm-forget-password-button fm-button-main-border-color">{{ buttonText }}</a>
  </template>
</template>

<script>
export default {
  name: 'ButtonPrimaryOutline',
  props: {
    buttonText: String,
    type: {
      type: String,
      default: null
    },
    href: {
      type: String,
      default: null
    },
    role: {
      type: String,
      default: null
    }
  }
};
</script>

<style scoped>
.fm-forget-password-button {
  margin-top: 1rem;
  font-size: 1rem;
  border-color: #1E90FF;
}

.fm-button-main-border-color {
  border-color: var(--dodger-blue);
  color: var(--dodger-blue);
}

.fm-button-main-border-color:hover {
  border-color: var(--dodger-blue);
  background-color: var(--dodger-blue);
  color: white;
}
</style>
